import { graphql } from "gatsby";
import { string } from "prop-types";
import React from "react";
import Layout from "../components/Layout/Layout";

import TestimonialsList from "../components/Testimonials/TestimonialsList";

import { TestimonialSIngle } from "../components/Testimonials/TestimonialSingle.";
import { Flex } from "../components/Flex/Flex";
import { Container } from "../components/Container/Container";
import { Hero } from "../components/Hero/Hero";
import { Section } from "../components/Section/Section";

const OfferList = ({ data, pageContext, location }: any) => {
	const lang = pageContext.lang;
	const allTestimonials = data.allTestimonial.nodes;

	const headerData = pageContext.pageData.hero;
	const titleData = headerData.title[lang];
	const descriptionData = headerData.description[lang];

	const mapLangTestimonial = allTestimonials.map((t: any, i: number) => {
		const mapDataObject = t.locales.find(
			(locale: any) => locale.iso === lang
		);

		const titleData: string = mapDataObject.title;
		const contentData: string = mapDataObject.content;

		return (
			<TestimonialSIngle
				key={i}
				id={i}
				titleData={titleData}
				contentData={contentData}
			/>
		);
	});

	return (
		<Layout
			department={data.department}
			location={location}
			lang={lang}
			otherLang={pageContext.otherLang}
			defLang={pageContext.defLang}
			metaInfo={pageContext.metaInfo}
		>
			<div className="page-wrapper">
			<Hero title={titleData} description={descriptionData}/>
			<Container>
			<Section>
					<Flex options={{ alignH: false, alignV: false }}>
						{mapLangTestimonial}
					</Flex>
				</Section>
			</Container>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
		allTestimonial {
			nodes {
				uid
				locales {
					content
					description
					iso
					metaTitle
					title
				}
			}
		}
	}
`;

export default OfferList;
