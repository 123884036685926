import React from "react";
import * as style from "./testimonial.module.css";

export function TestimonialSIngle({ id, titleData, contentData }: any) {
	const markup = () => {
		return { __html: contentData };
	};
	return (
		<article key={id} className={style.singleTestimonial}>
			<div dangerouslySetInnerHTML={markup()} />
			<p className={style.name}> {titleData} </p>
		</article>
	);
}
